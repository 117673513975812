import React from "react"
import * as styles from "./basic-button.module.css"

export default function BasicButton(props) {
	return (
		<a className={styles.basic_button_link} href={props.to}>
			<div className={styles.basic_button}>
				<span>{props.display_name}</span>
			</div>
		</a>
	)
}
