import React from "react"
import { Helmet } from "react-helmet"
import * as styles from "./layout.module.css"
import "@fontsource/noto-sans"

export default function Layout({ children }) {
	return (
		<div className={styles.layout}>
			<Helmet>
				<meta charset="utf-8" />

				<title>Loot Snake - Last Quarter Studios</title>

				<meta name = "theme-color" content = "#000000" />

				<meta name = "keywords" content = "last, quarter, studios, studio, lastquarterdev, indie, loot snake, loot, snake" />
				<meta name = "author" content = "Last Quarter Studios" />
				<meta name = "description" content = "Loot Snake" />

				<meta name = "google" content = "nositelinkssearchbox" />
				<meta name = "google" content = "notranslate" />

				<meta name = "twitter:card" content = "summary_large_image" />
				<meta name = "twitter:site" content = "lootsnake.lastquarterstudios.com" />
				<meta name = "twitter:creator" content = "@lastquarterdev" />
				<meta name = "twitter:title" content = "Loot Snake" />
				<meta name = "twitter:description" content = "Loot Snake website" />
				<meta name = "twitter:dnt" content = "on" />
			</Helmet>

			{children}

			<div className={styles.footer}>© {new Date().getFullYear()} Last Quarter Studios Limited Partnership. All rights reserved. <a href="https://lastquarterstudios.com/privacy-policy">Privacy policy.</a></div>
		</div>
	)
}