import React from "react"
import * as styles from "./socials.module.css"
import BasicButton from "../basic-button/basic-button"

export default function Socials() {
	return (
		<div className={styles.container}>
			<BasicButton to="https://twitter.com/lastquarterdev" display_name="Twitter" />
			<BasicButton to="https://discord.lastquarterstudios.com" display_name="Discord" />
			<BasicButton to="https://lootsnakegame.tumblr.com/" display_name="Devblog" />
		</div>
	)
}
