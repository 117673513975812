import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Gallery from '@browniebroke/gatsby-image-gallery'
import Layout from "../components/layout"
import Socials from "../components/socials/socials"
import BasicButton from "../components/basic-button/basic-button"

import featureGIF from '../images/features/feature.gif'

import "@fontsource/noto-sans/700.css"
import "@fontsource/archivo-black/"

export default function Home({ data }) {
	const screenshots = data.allFile.edges.map(({ node }) => node.childImageSharp)
	const banner = getImage(data.file)

	const lightbox_options = {
		imagePadding: 100,
		enableZoom: false
	}

	return (
		<Layout>
			<GatsbyImage image={banner} />

			<div className="generic_content">
				<h1>Combination of a top-down dungeon crawler and the classic game Snake.</h1>

				<img src={featureGIF} alt="Animated GIF of the snake in the starting room." width="50%"/>

				<div className="text_content">
					<p>You are the snake and must crawl through dungeons collecting as much loot as you can while fighting enemies. Along the way you'll get new powerups which use the loot you collect as ammo. The game features a map editor, so you can create your own maps and share them with others.</p>

					<p>The game is still in the pre-alpha stage, so lot's of new features and stuff is planned to come out in the future.</p>

					<p>This game is not currently in active development.</p>
				</div>

				<h2>Screenshots</h2>

				<div className="screenshot_gallery">
					<Gallery images={screenshots} lightboxOptions={lightbox_options} />
				</div>

				<div className="call_to_action">
					Get the game
				</div>

				<div style={{'display': 'flex', 'justify-content': 'center', 'margin-bottom': '4rem'}}>
					<BasicButton style={{width: '30rem'}} to="https://lastquarterstudios.itch.io/loot-snake" display_name="Pre-alpha 0.3.0" />
				</div>

				<h2>Follow the development</h2>

				<Socials />
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query ImagesForABP {
		allFile(filter: {relativeDirectory: {eq: "screenshots"}}, sort: {fields: name}) {
			edges {
				node {
					childImageSharp {
						thumb: gatsbyImageData(
							width: 200
							height: 200
							placeholder: BLURRED
						)
						full: gatsbyImageData(
							quality: 100
							layout: FULL_WIDTH
						)
					}
				}
			}
		}
		file(relativeDirectory: {eq: "banners"}, name: {eq: "ls"}) {
			id
			name
			childImageSharp {
				gatsbyImageData(
					quality: 100
					layout: FULL_WIDTH
				)
			}
		}
	}
`
